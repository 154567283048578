import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { isSignedIn } from 'Util/Auth';
import { handleGAEventsTracker } from 'Util/Helper'; // Import GA Tracking

export const GuestWishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GuestWishlist/GuestWishlist.dispatcher'
);

/** @namespace myApp/Component/ProductWishlistButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace myApp/Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    addProductToGuestWishlist: (magentoProduct) => GuestWishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addItemToWishlist(dispatch, magentoProduct)
    ),
    removeProductFromGuestWishlist: (options) => GuestWishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
    )
});

/** @namespace myApp/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    async toggleProductInWishlist(add = true) {
        const {
            magentoProduct,
            magentoProduct: [{ sku }] = [],
            isAddingWishlistItem,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId,
            addProductToGuestWishlist,
            removeProductFromGuestWishlist,
            productWishlist
        } = this.props;

        if (isAddingWishlistItem) {
            return null;
        }

        if (!isSignedIn()) {
            this.setWishlistButtonLoading(true);
            if (add) {
                await addProductToGuestWishlist(magentoProduct);

                // Fire GA event for add_to_wishlist (Guest)
                handleGAEventsTracker('event', 'add_to_wishlist', {
                    currency: 'INR',
                    value: productWishlist?.price_range?.minimum_price?.final_price?.value || 0,
                    items: [{
                        item_id: productWishlist?.sku,
                        item_name: productWishlist?.name,
                        quantity: 1,
                        price: productWishlist?.price_range?.minimum_price?.final_price?.value || 0
                    }]
                });

                return null;
            }

            const wishlistItem = this.getWishlistItem(sku);
            if (!wishlistItem) {
                return null;
            }

            const { wishlist: { id: itemId, wishlist_sku } } = wishlistItem;

            return removeProductFromGuestWishlist({ item_id: itemId, wishlist_sku, sku });
        }

        this.setWishlistButtonLoading(true);

        if (add) {
            await addProductToWishlist({
                items: magentoProduct,
                wishlistId
            });

            // Fire GA event for add_to_wishlist (Logged-in users)
            handleGAEventsTracker('event', 'add_to_wishlist', {
                currency: 'INR',
                value: productWishlist?.price_range?.minimum_price?.final_price?.value || 0,
                items: [{
                    item_id: productWishlist?.sku,
                    item_name: productWishlist?.name,
                    quantity: 1,
                    price: productWishlist?.price_range?.minimum_price?.final_price?.value || 0
                }]
            });

            return null;
        }

        const wishlistItem = this.getWishlistItem(sku);
        if (!wishlistItem) {
            return null;
        }

        const { wishlist: { id: itemId } } = wishlistItem;

        return removeProductFromWishlist({ item_id: itemId });
    }

    getWishlistItem(sku) {
        const { productsInWishlist } = this.props;

        if (!productsInWishlist) {
            return null;
        }

        return Object.values(productsInWishlist).find(
            ({ sku: wishlistSku }) => sku === wishlistSku
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
