/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ReactGA from 'react-ga4';

// import history from 'Util/History';
import getStore from 'Util/Store';

import './GoogleAnalytics.style';

// history.listen((location) => {
//     ReactGA.send({ hitType: 'pageview', page: location.pathname });
// });

/** @namespace Ambab/Google_analytics/Component/GoogleAnalytics/Component */
export class GoogleAnalyticsComponent extends PureComponent {
    static propTypes = {
        history: PropTypes.objectOf.isRequired
    };

    componentDidMount() {
        const { google_analytics_account = null } = this.state;

        if (google_analytics_account) {
            ReactGA.initialize(google_analytics_account);
            this.hitPageView();
        }
    }

    componentDidUpdate(prevProps) {
        const { history: { location } } = this.props;
        const { history: { prevLocation } } = prevProps;

        if (location.pathname !== prevLocation.pathname) {
            this.hitPageView();
        }
    }

    __construct(props) {
        super.__construct(props);

        const state = getStore().getState();

        this.state = {
            google_analytics_account: state.ConfigReducer.google_analytics_account
        };

        // if (location.host === 'uat.underdogathletics.in') {
        //     this.state = {
        //         google_analytics_account: state.ConfigReducer.google_analytics_account
        //     };
        // }

        // if (location.host === 'www.underarmour.in') {
        //     this.state = {
        //         google_analytics_account: state.ConfigReducer.google_analytics_account
        //     };
        // }
    }

    hitPageView = () => {
        // const { google_analytics_account = null } = this.state;

        // if (google_analytics_account) {
        //     const { history: { location } } = this.props;
        //     ReactGA.send({ hitType: 'pageview', page: location.pathname });
        // }
    };

    render() {
        return null;
    }
}

export default GoogleAnalyticsComponent;
