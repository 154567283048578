/* eslint-disable fp/no-let */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import ProductLinks from 'Component/ProductLinks';
import { CROSS_SELL } from 'Store/LinkedProducts/LinkedProducts.reducer';

// import { ProductType } from 'Type/ProductList';
import { ADD_TO_CART_POPUP_ID } from './AddToCartPopup.config';

import './AddToCartPopup.style';

/** @namespace Ambab/AddtoCartPopup/Component/AddToCartPopup/Component */
export class AddToCartPopupComponent extends PureComponent {
  static propTypes = {
      productData: PropTypes.any,
      quantity: PropTypes.number,
      onClickViewBagAndCheckout: PropTypes.func.isRequired
  };

  static defaultProps = {
      productData: {},
      quantity: 0
  };

  renderConfigurableAttributes() {
      const {
          productData: { parameters },
          productData: { product: { attributes } }
      } = this.props;

      if (!attributes) {
          return null;
      }

      // Extract attribute values
      const colorOption = attributes.color;
      const sizeOption = attributes.size;

      return (
          <>
            { colorOption && Object.keys(colorOption.attribute_options).length > 0 && (
                <p block="ProductConfigurableAttributes" elem="Size">
                    Color:
                    <span block="ProductConfigurableAttributes" elem="SelectedOptionLabel">
                        { parameters.color ? colorOption.attribute_options[parameters.color]?.label || 'N/A' : 'N/A' }
                    </span>
                </p>
            ) }

            { sizeOption && Object.keys(sizeOption.attribute_options).length > 0 ? (
                <p block="ProductConfigurableAttributes" elem="Size">
                    Size:
                    <span block="ProductConfigurableAttributes" elem="SelectedOptionLabel">
                        { parameters.size ? sizeOption.attribute_options[parameters.size]?.label || 'N/A' : 'N/A' }
                    </span>
                </p>
            ) : (
                <p block="ProductConfigurableAttributes" elem="Size">No size options available</p>
            ) }
          </>
      );
  }

  renderProductCard(selectProduct, _quantity) {
      const {
          productData: { product: { name, sku }, product, parameters }
      } = this.props;

      const variants = product?.variants;

      let productToRender = selectProduct;

      if (variants && Array.isArray(variants)) {
          // Find a matching variant based on the available parameters
          const selectedVariant = variants.find((variant) => {
              const matchesSize = parameters.size
                  ? variant.attributes.size?.attribute_value === parameters.size
                  : true;
              const matchesColor = parameters.color
                  ? variant.attributes.color?.attribute_value === parameters.color
                  : true;

              return matchesSize && matchesColor;
          });

          if (selectedVariant) {
              productToRender = selectedVariant;
          }
      }

      return (
      <div block="Main">
        <img block="Img" src={ selectProduct.image.url } alt={ selectProduct.name } />
        <div block="ProductInfo">
          <h2 block="Main" elem="ProductName">
            { name }
          </h2>
          <p block="Main" elem="Sku">
            SKU:
            { ' ' }
            { productToRender.sku }
          </p>
          { this.renderConfigurableAttributes() }
          <p block="ProductConfigurableAttributes" elem="SelectedOptionLabelPrice">
          ₹
            <span>
                    { productToRender.price_range.maximum_price.final_price.value }
            </span>
          </p>
        </div>
      </div>
      );
  }

  render() {
      const {
          productData: {
              selectProduct, quantity, parameters, product
          }, productData, onClickViewBagAndCheckout, onClickContinueShopping
      } = this.props;

      const variants = product?.variants;

      let productToRender = product;

      if (variants && Array.isArray(variants)) {
          const selectedVariant = variants.find((variant) => variant.attributes.color.attribute_value === parameters.color
        && variant.attributes.size.attribute_value === parameters.size);

          if (selectedVariant) {
              productToRender = selectedVariant;
          }
      }

      return (
      <div block="AddToCartPopup">
        <Popup
          id={ ADD_TO_CART_POPUP_ID }
          clickOutside={ false }
          mix={ { block: 'AddToCartPopup' } }
        >
          { productToRender ? this.renderProductCard(productToRender, quantity, product) : '' }
          <div block="RelatedProduct">
            <div block="InnerDiv">
              <ProductLinks
                linkType={ CROSS_SELL }
                title={ __('You might also like') }
                areDetailsLoaded
                numberOfProductsToDisplay={ 3 }
                numberOfProductsToDisplayInMobile={ 2 }
              />
            </div>
          </div>
          <div block="PopupButtons">
            <button
              onClick={ onClickContinueShopping }
              className="PopupButtons-ShoppingBtn"
            >
              Continue Shopping
            </button>
            <button
              onClick={ onClickViewBagAndCheckout }
              className="CartBtn"
              style={ { textDecoration: 'none' } }
            >
              View Bag & Checkout
            </button>
          </div>
        </Popup>
      </div>
      );
  }
}

export default AddToCartPopupComponent;
