import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';
/** @namespace myApp/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    getResetPasswordMutation(options) {
        const {
            token, id, password, password_confirmation
        } = options;

        return new Field('s_resetPassword')
            .addArgument('token', 'String!', token)
            .addArgument('customer_id', 'String!', id)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addField('status');
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'gender',
            'taxvat',
            'id',
            'is_subscribed',
            this._getAddressesField()
        ];
    }
}

export default new MyAccountQuery();
