/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unescaped-entities */
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import {
    MyAccountOrderItemsTableRow as SourceMyAccountOrderItemsTableRow
} from 'SourceComponent/MyAccountOrderItemsTableRow/MyAccountOrderItemsTableRow.component';
import history from 'Util/History';
import { formatPrice } from 'Util/Price';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import './MyAccountOrderItemsTableRow.override.style';

/** @namespace myApp/Component/MyAccountOrderItemsTableRow/Component */
export class MyAccountOrderItemsTableRowComponent extends SourceMyAccountOrderItemsTableRow {
    state = {
        cancelquantity: 0

    };

    renderPrductSku() {
        const {
            product: {
                product_sku
            }
        } = this.props;

        return (
            <dl>
                <dt block="MyAccountOrderItemsTableRow" elem="OptionLabel">Sku:</dt>
                <dl block="MyAccountOrderItemsTableRow" elem="OptionValue">{ product_sku }</dl>
            </dl>
        );
    }

    renderNameAndOptions() {
        const { product: { product_name } } = this.props;

        return (
            <>
                <span
                  block="MyAccountOrderItemsTableRow"
                  elem="Name"
                >
                    { product_name }
                </span>
                { this.renderPrductSku() }
                { this.renderSelectedAndEnteredOptions() }
            </>
        );
    }

    renderSelectItemQty() {
        const {

            product: { quantity_ordered }
        } = this.props;

        if (!quantity_ordered) {
            return null;
        }

        return (
            <dl>
                <dt block="MyAccountOrderItemsTableRow" elem="OptionLabel">Qty:</dt>
                <dt block="MyAccountOrderItemsTableRow" elem="OptionValue">{ quantity_ordered }</dt>
            </dl>
        );
    }

    renderOrderCancelled() {
        const {

            product: { quantity_canceled }
        } = this.props;

        if (!quantity_canceled) {
            return null;
        }

        return (
            <dl>
                <dt block="MyAccountOrderItemsTableRow" elem="OptionLabel">Order Cancelled:</dt>
                <dt block="MyAccountOrderItemsTableRow" elem="OptionValue">{ quantity_canceled }</dt>
            </dl>
        );
    }

    renderSelectedAndEnteredOptions() {
        const {
            selectedOptions,
            enteredOptions
        } = this.props;
        const { renderOption } = this.renderMap;
        if (!selectedOptions.length && !enteredOptions.length) {
            return null;
        }

        return (
            <>
                { selectedOptions.map(renderOption) }
                { enteredOptions.map(renderOption) }
            </>
        );
    }

    renderOption(option) {
        const {
            label,
            items,
            value
        } = option || [];

        if (items) {
            return null;
        }

        return (
            <dl key={ `${ label }-${ value }` }>
                <dt
                  block="MyAccountOrderItemsTableRow"
                  elem="OptionLabel"
                >
                   { label }
                    :
                </dt>
                { this.renderOptionContent(option) }
            </dl>
        );
    }

    renderOrderShippingAddress() {
        const { order: { shipping_address } } = this.props;
        if (!shipping_address) {
            return null;
        }

        return (
            <div block="MyAccountOrderItemsTableRow" elem="ShippingAddress">
                <h4>Shipped To: </h4>
                <p>
                    { `${shipping_address.firstname}` }
                </p>
                <p>
                    { `${shipping_address.city}` }
                </p>
                <p>
                    { `${shipping_address.street}` }
                </p>
                <p>
                    { `${shipping_address.region}` }
                </p>
                <p>
                    { __('Pincode:') }
                    { ' ' }
                    { shipping_address.postcode }
                </p>
                { /* <p>
                    { __('Mobile:') }
                    { ` ${shipping_address.telephone}` }
                </p> */ }
            </div>

        );
    }

    renderSpecialprice() {
        const {
            product: {
                orignal_price, special_price, special_price_flag, product_type
            }
        } = this.props;

        if (product_type === 'mageworx_giftcards') {
            return (
                <p className="price">
                    <span>
                        { (special_price) }
                    </span>
                </p>
            );
        }

        if (special_price_flag === false) {
            return (
                <p className="price">
                    <span>
                        { (orignal_price) }
                    </span>
                </p>
            );
        }

        return (
            <p className="price">
                <span>
                    { (special_price) }
                </span>
                <del>
                    { (orignal_price) }
                </del>
            </p>
        );
    }

    handleExchangeClick = () => {
        const { product: { order_item_id }, order: { id } } = this.props;
        history.push({
            pathname: `/my-returns/new-return/${ id }`,
            state: {
                itemid: `${ order_item_id }`,
                returnresolution: 1
            }
        });
    };

    handleReturnClick = () => {
        const { product: { order_item_id }, order: { id } } = this.props;
        history.push({
            pathname: `/my-returns/new-return/${ id }`,
            state: {
                itemid: `${ order_item_id }`,
                returnresolution: 2
            }
        });
    };

    renderButton() {
        const {
            product: {
                product_url, config_sku, product_type, qty_available_to_return
            }, order: {
                status,
                exchange_order_status,
                order_valid_forrma,
                giftcard_used_status
            }, addProductToCart
        } = this.props;

        if (product_type === 'mageworx_giftcards') {
            return (
                <Link to={ `${product_url}` } block="MyAccountOrderItemsTableRow" elem="BuyAgain">
            Buy Again
                </Link>
            );
        }

        if (giftcard_used_status) {
            return (
                <>
                <button
                  block="MyAccountOrderItemsTableRow"
                  elem="BuyAgain"
                  onClick={ addProductToCart }
                >
                    Buy Again
                </button>

                { ['Partially Exchange', 'Complete', 'Delivered'].includes(status) && (
                    <button
                      block="MyAccountOrderItemsTableRow"
                      elem="Exchange"
                      onClick={ this.handleExchangeClick }
                    >
                        Exchange
                    </button>
                ) }
                </>
            );
        }

        if (!qty_available_to_return || exchange_order_status === true && status === 'Delivered') {
            return (
            <button
              block="MyAccountOrderItemsTableRow"
              elem="BuyAgain"
              onClick={ addProductToCart }
            >
                Buy Again
            </button>
            );
        }

        if (order_valid_forrma && (status === 'Partially Exchange' || status === 'Complete' || status === 'Delivered')) {
            return (
                <>
                    <button
                      block="MyAccountOrderItemsTableRow"
                      elem="BuyAgain"
                      onClick={ addProductToCart }
                    >
                        Buy Again
                    </button>
                    <button
                      block="MyAccountOrderItemsTableRow"
                      elem="Exchange"
                      onClick={ this.handleExchangeClick }
                    >
                        Exchange
                    </button>
                    <button
                      block="MyAccountOrderItemsTableRow"
                      elem="Return"
                      onClick={ this.handleReturnClick }
                    >
                        Return
                    </button>
                </>
            );
        }

        return (
            <button
              block="MyAccountOrderItemsTableRow"
              elem="BuyAgain"
              onClick={ addProductToCart }
            >
                Buy Again
            </button>
        );
    }

    onHandleCancelQuantityChanger = (value) => {
        this.setState({
            cancelquantity: value
        });
    };

    renderCancelQtyDrop() {
        const { cancelquantity } = this.state;

        const {
            order: { id, status, exchange_order_status },
            product: {
                order_item_id, quantity_ordered, qty_available_to_cancel, product_type
            },
            cancelOrderItemPopup
        } = this.props;

        const maxCancelQuantity = qty_available_to_cancel;
        const minCancelQuantity = 1;

        const selectOptions = [];

        for (let i = minCancelQuantity; i <= maxCancelQuantity; i++) {
            selectOptions.push({
                id: i,
                value: i,
                label: i.toString()
            });
        }

        if (!qty_available_to_cancel) {
            return null;
        }

        if (exchange_order_status && status === 'Processing') {
            return null;
        }

        if (exchange_order_status && status === 'In Process') {
            return null;
        }

        if (exchange_order_status && status === 'Order Exchange') {
            return null;
        }

        if (status === 'Pending') {
            return null;
        }

        if (product_type === 'mageworx_giftcards') {
            return null;
        }

        return (
            <div block="MyAccountOrderItemsTableRow" elem="CancelWrapper">
                <Field
                  type={ FIELD_TYPE.select }
                  label={ __('Cancel Qty') }
                  attr={ {
                      id: 'cancel_item_qty',
                      name: 'cancel_item_qty',
                      value: this.state.cancelquantity
                  } }
                  events={ { onChange: this.onHandleCancelQuantityChanger } }
                  options={ selectOptions }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                      isRequired: true,
                      range: {
                          min: minCancelQuantity,
                          max: maxCancelQuantity
                      }
                  } }
                  mix={ { block: this.className, elem: 'Qty' } }
                  validateOn={ ['onChange'] }
                />

                <button
                  block="Button MyAccountOrderItemsTableRow"
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => cancelOrderItemPopup({ id, order_item_id, cancelquantity }) }
                  elem="Cancel"
                  disabled={ !cancelquantity }
                >
                    Cancel
                </button>

            </div>
        );
    }

    renderActionWrapper() {
        const {
            product: {
                quantity_canceled, qty_available_to_cancel
            }
        } = this.props;

        return (
            <div block="MyAccountOrderItemsTableRow" elem="Actions">
                { this.renderButton() }
                { !qty_available_to_cancel && quantity_canceled ? <span block="textred cancelItemMsg">This item has been Cancelled.</span> : null }
                { quantity_canceled && qty_available_to_cancel ? <span block="textred cancelItemMsg">This item has been Partially Cancelled.</span> : null }
            </div>
        );
    }

    renderProductImage() {
        const { product: { product_image } } = this.props;

        return (
            <Image src={ product_image } alt="image" />
        );
    }

    renderDesktopTableRow() {
        const {
            enteredOptions = []
        } = this.props;
        const isWithEnteredItems = !!enteredOptions[0]?.items;

        return (
            <>
                <li
                  block="MyAccountOrderItemsTableRow"
                  elem="RowWrapper"
                  mods={ { isWithEnteredItems } }
                >
                    <div block="MyAccountOrderItemsTableRow" elem="Image">
                        { this.renderProductImage() }
                    </div>
                    <div block="MyAccountOrderItemsTableRow" elem="ProductDesc">
                        { this.renderNameAndOptions() }
                        { this.renderSelectItemQty() }
                        { this.renderOrderCancelled() }
                        { this.renderOrderShippingAddress() }
                        { /* { this.renderRowSubtotal() } */ }
                        { this.renderSpecialprice() }
                        { this.renderActionWrapper() }
                        { this.renderCancelQtyDrop() }
                    </div>
                </li>
                { this.renderEnteredOptionsAsRow() }
            </>
        );
    }

    render() {
        const { isLoading } = this.props;
        return (
            <>
            <Loader isLoading={ isLoading } />
            { this.renderDesktopTableRow() }

            </>
        );
    }
}

export default MyAccountOrderItemsTableRowComponent;
